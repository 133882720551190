'use client';
import { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import SkeletonOfferItem from '../skeletons/SkeletonOfferItem';
import usePaginatedService from '@/hooks/usePaginatedService';
import SomethingWrong from '../SomethingWrong';
import NoDataFound from '@/components/NoDataFound';
import { LANGUAGES_CONTENT } from '@/constants/language';
import { StorageService } from '@/service/StorageService';
import OfferFilter from '@/app/(Auth)/(home)/components/OfferFilter';
import OfferSort from '@/app/(Auth)/(home)/components/OfferSort';
/**
 *
 * @param {{
 * service: any,
 * }} param0
 * @returns
 */
export default function PaginatedOffersList({
  service,
  emptyMessage = null,
  triggerRefresh,
  filterFn,
  renderOffer,
  onNoOffers,
  isTags = false,
  isSort = false,
  setTags,
  tags,
  setSort,
  sort,
}) {
  const {
    data,
    loadMoreData,
    hasMoreData,
    isLoaded,
    refreshData,
    isLoading,
    error,
  } = usePaginatedService(service, {
    makeFirstCall: !(isTags || isSort),
    count: 10,
    serviceArgs: {
      tags,
      sort,
    },
  });

  useEffect(() => {
    if (!triggerRefresh) return;
    refreshData();
  }, [triggerRefresh]);

  useEffect(() => {
    if (isTags || isSort) {
      refreshData();
    }
  }, [tags, sort]);

  const filteredData = useMemo(() => {
    if (!filterFn) {
      return data;
    }
    return filterFn(data);
  }, [data]);

  const actionJsx = (
    <div className="sticky top-0 z-10 flex items-center gap-1 bg-white py-2">
      {isSort && (
        <div className="my-2">
          <OfferSort activeTags={tags} activeSort={sort} onSubmit={setSort} />
        </div>
      )}
      {isTags && (
        <div className="my-2">
          <OfferFilter activeTags={tags} activeSort={sort} onSubmit={setTags} />
        </div>
      )}
    </div>
  );

  if (!isLoaded)
    return (
      <div className="grid gap-2">
        {actionJsx}
        <SkeletonOfferItem count={5} />
      </div>
    );

  if (!isLoading && !!error)
    return (
      <SomethingWrong
        onRefresh={refreshData}
        classes={{
          container: '',
          header: 'landscape:text-xl',
        }}
      />
    );

  const dataLength = hasMoreData ? data?.length + 1 : data?.length;
  const EMPTY_MESSAGE =
    LANGUAGES_CONTENT[StorageService.getLanguage()]['empty'].offers.found;

  if (!data?.length) {
    onNoOffers?.();
  }

  const handleResetFilters = () => {
    setTags([]);
  };

  return (
    <div>
      {actionJsx}
      {!filteredData?.length ? (
        <NoDataFound
          emptyMessage={emptyMessage || EMPTY_MESSAGE}
          cta={
            isTags && tags?.length
              ? LANGUAGES_CONTENT[StorageService.getLanguage()]['empty'].offers
                  .resetFilterCta
              : null
          }
          onCtaClick={handleResetFilters}
        />
      ) : (
        <InfiniteScroll
          dataLength={dataLength}
          next={loadMoreData}
          hasMore={hasMoreData}
          scrollThreshold={'400px'}
          loader={
            <div className="mt-3">
              <SkeletonOfferItem count={3} />
            </div>
          }
        >
          <div className="grid gap-3">{filteredData?.map(renderOffer)}</div>
        </InfiniteScroll>
      )}
    </div>
  );
}
